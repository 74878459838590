import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'galleryProducts',
})
export class GalleryProductsPipe implements PipeTransform {
  transform(products: any[], user: any, buyControl): any[] {
    console.log('products :>> ', products);
    if (!products) return products;
    const rolId = user?.rolId;
    const dist = rolId === 7;
    const client = rolId !== 7;
    if (buyControl?.hiddeZeroExistence) {
      const newArr = [];
      products.map((item) => {
        if (client) {
          if (
            (item.countHidden &&
              (item.existence >= 0 || item.existence === null)) ||
            (!item.countHidden && item.existence !== 0)
          ) {
            newArr.push(item);
          }
        } else if (dist) {
          if (
            (item.countHidden &&
              (item.existenceWSale >= 0 ||
                item.existenceWSale === null ||
                item.existence >= 0 ||
                item.existence === null)) ||
            (!item.countHidden &&
              (item.existenceWSale !== 0 || item.existence !== 0))
          ) {
            newArr.push(item);
          }
        }
      });
      products = newArr;
    } else {
      products.map((item) => {
        if (
          (!item.countHidden && client && item?.existence === 0) ||
          (dist && item?.existenceWSale === 0 && item?.existence === 0)
        ) {
          item.noExistence = true;
        }
      });
    }
    return products;
  }
}
